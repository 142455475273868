import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.css']
})
export class IncomeComponent implements OnInit {

  public text1:boolean = true;
  public text2:boolean = false;

  constructor() { }

  showtext1= () => {
    this.text1 = true;
    this.text2 = false
  }

  showtext2= () => {
    this.text1 = false;
    this.text2 = true
  }

  ngOnInit() {
  }

}
