import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: ['./contractors.component.css']
})
export class ContractorsComponent implements OnInit {

  public contactors: any = [];

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getcontractors();
  }


  getcontractors(){

    this.http.get('contractors').subscribe(response => {

    if(response['status'].code == "200"){

      this.contactors = response["result"].contractors;

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

}
