import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lifeaterc',
  templateUrl: './lifeaterc.component.html',
  styleUrls: ['./lifeaterc.component.css']
})
export class LifeatercComponent implements OnInit {

  public div1:boolean=true;
  public div2:boolean=false;
  public div3:boolean=false;
  public humnanRes: any
  public benefitsItem: any
  constructor(public http: HttpClient, private toastr: ToastrService,private route: ActivatedRoute,private router : Router) { }

  
  humanRes(){

    this.http.get(`life/hr`).subscribe(response => {

      if(response['status'].code == "200"){
        this.humnanRes = response["result"];

      } 
    });

  }

  benefits(){

    this.http.get(`life/benefits`).subscribe(response => {

      if(response['status'].code == "200"){
        this.benefitsItem = response["result"];

      } 
    });

  }

  div1Function(){
      this.div1=true;
      this.div2=false;
      this.div3=false
  }

  div2Function(){
      this.div2=true;
      this.div1=false;
      this.div3=false
  }

  div3Function(){
      this.div3=true;
      this.div2=false;
      this.div1=false
  }
  ngOnInit() {
    this.humanRes();
    this.benefits();
  }


}
