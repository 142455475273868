import { Component, OnInit } from '@angular/core';
 
@Component({
 selector: 'app-homeslider',
 templateUrl: './homeslider.component.html',
 styleUrls: ['./homeslider.component.scss']
})
export class HomesliderComponent implements OnInit {
 
public lang: any;
 
 constructor() { }
 
 ngOnInit() {
 this.lang = localStorage.getItem("lang");
 setTimeout(function(){
  document.getElementById("videoId")[0].play();
 },1000);
 }
 
readmorebutton(){
 
 window.open("/about-us", "_Self");
 
}
 
}