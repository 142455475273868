import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refiningprocess',
  templateUrl: './refiningprocess.component.html',
  styleUrls: ['./refiningprocess.component.css']
})
export class RefiningprocessComponent implements OnInit {

public lang: any;

  constructor() { }

  ngOnInit() {
    this.lang = localStorage.getItem("lang");
  }

}
