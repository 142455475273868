import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

public contactObj: any = {

  name: "",
 email: "",
  phone: "",
 "category_id": 3,
  subject: "",
  notes: ""

};

public categories: any;

constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getcategories();
  }

  getcategories(){

    this.http.get('contact/form').subscribe(response => {

    if(response['status'].code == "200"){

      this.categories = response["result"].categories;

    }

    else { this.toastr.error(response['status'].message); }

  });

  }


  contactusform(){

    this.http.post('contact/customer', this.contactObj).subscribe(response => {

    if(response['status'].code == "200"){

      this.toastr.success(response['status'].message);
      this.contactObj = {

        name: "",
        email: "",
        phone: "",
      category_id: 3,
       subject: "",
        notes: ""

      };

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

}
