import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SimpleCrypt } from 'ngx-simple-crypt';

import { ajax } from 'rxjs/ajax';
import { retry, retryWhen, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  public token : any;
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(localStorage.getItem('User') == null){
          if(req.url.includes('https://burgundy-duck-6145.twil.io/chat-token') || req.url.match(/(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg)/g) || req.url == './assets/i18n/en.json' || req.url == './assets/i18n/ar.json' || req.url == 'assets/locales.json'){
          }else{
                    req = req.clone({
                    url: `${environment.apiUrl}/${req.url}`,
                    setHeaders: {
                      locale: localStorage.getItem("lang")
                    }
                    });
        }

}else{
      let objCrypt = new SimpleCrypt();
      const token: string = objCrypt.decode('INNOVx123',localStorage.getItem('User'));
      if(req.url.includes('https://burgundy-duck-6145.twil.io/chat-token') || req.url.match(/(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg)/g) || req.url == './assets/i18n/en.json' || req.url == './assets/i18n/ar.json' || req.url == 'assets/locales.json'){
      }else{
                req = req.clone({
                url: `${environment.apiUrl}/${req.url}`,
                setHeaders: {
                            Authorization: `Bearer `+ token,
                            locale: localStorage.getItem("lang")
                }
                });
    }

}
    return next.handle(req).pipe(
      retryWhen( (error) => error.pipe(
        delay( 5000 )
    )),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
}

}
