import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  public products:any;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getproducts();
  }

  getproducts(){

    this.http.get('products').subscribe(response => {

    if(response['status'].code == "200"){

      this.products = response["result"].products;

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

}
