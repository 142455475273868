import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-becomefamily',
  templateUrl: './becomefamily.component.html',
  styleUrls: ['./becomefamily.component.css']
})
export class BecomefamilyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  readmorebutton(){

    window.open("/careers", "_Self");

  }

}
