import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-whoweare',
  templateUrl: './whoweare.component.html',
  styleUrls: ['./whoweare.component.css']
})
export class WhoweareComponent implements OnInit {

  public whoweare: any;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getwhoweare();
  }

  //who we are

  getwhoweare(){

    this.http.get('home/about').subscribe(response => {

    if(response['status'].code == "200"){

      this.whoweare = response["result"];

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

  //who we are

}
