import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {

  public data:any;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getcareers();
  }


  getcareers(){

    this.http.get('careers').subscribe(response => {

    if(response['status'].code == "200"){

      this.data = response["result"];

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

}
