import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit {

  public excutives:any;

  public team:any;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getexcutive();
    this.getteam();
  }

  getexcutive(){

    this.http.get('teams/executive').subscribe(response => {

    if(response['status'].code == "200"){

      this.excutives = response["result"];

    }

    else { this.toastr.error(response['status'].message); }

  });

  }


  getteam(){

    this.http.get('teams/management').subscribe(response => {

    if(response['status'].code == "200"){

      this.team = response["result"];

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

}
