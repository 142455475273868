import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-subnews',
  templateUrl: './subnews.component.html',
  styleUrls: ['./subnews.component.css']
})
export class SubnewsComponent implements OnInit {

  public news: any;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getnews();
  }

  getnews(){

    this.http.get('news').subscribe(response => {

    if(response['status'].code == "200"){

      this.news = response["result"];

    }

    else { this.toastr.error(response['status'].message); }

  });

  }


  gotonews(id){

    window.open("/subnews/"+ id, "_Self");

  }

}
