import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})
export class EducationComponent implements OnInit {
  public text1:boolean = true;
  public text2:boolean = false;
  constructor() { }
  showtext1= () => {
    this.text1 = true;
    this.text2 = false
  }

  showtext2= () => {
    this.text1 = false;
    this.text2 = true
  }
  ngOnInit() {
  }

}
