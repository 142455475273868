import { Component, OnInit } from '@angular/core';
// import { DataService } from './data.service';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public langSelector : any = true;
  public showmessage : any = true;
  public user : any ;
  public cronaBar : any = true ;
  
  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.cronaBar = sessionStorage.getItem("cronaBar")
    console.log(this.cronaBar)

  //
  // if(this.router.url == "/"){
  //
  //   this.showmessage = true;
  //
  // }
  // 	else{
  //     this.showmessage = false ;
  // }





      if(localStorage.getItem('lang') != null && localStorage.getItem('lang') != undefined){
      this.translate.use(localStorage.getItem('lang'));

      if(localStorage.getItem('lang') == 'en'){
        this.langSelector = true;
      }else{
        this.langSelector = false;
      }
  }else{
      this.translate.use('en');

      localStorage.setItem('lang','en');
  }



  }


   closeModal(){
      document.getElementById('myMessage').style.display = "none";
      sessionStorage.setItem("cronaBar","false")
      this.cronaBar = sessionStorage.getItem("cronaBar")
      console.log(this.cronaBar)
    }


    switchLang(lang : any){
      lang = !lang;
    if(lang == true){
          this.translate.use('en');
          localStorage.setItem('lang','en');
    }else{
          this.translate.use('ar');
          localStorage.setItem('lang','ar');
    }
    this.langSelector = lang;
    console.log(lang)
    location.reload();



  }

}
