import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-numbers',
  templateUrl: './numbers.component.html',
  styleUrls: ['./numbers.component.css']
})
export class NumbersComponent implements OnInit {

  public numbersaterc: any;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getnumbersaterc();
  }

  //numbers at erc

  getnumbersaterc(){

    this.http.get('home/featured').subscribe(response => {

    if(response['status'].code == "200"){

      this.numbersaterc = response["result"];
      this.numbersaterc['counters'].map( count => {

        if(count['measurement'] == null){
          count['measurement'] = "";
        }

      });

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

  //numbers at erc

}
