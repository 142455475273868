import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ercexecutive',
  templateUrl: './ercexecutive.component.html',
  styleUrls: ['./ercexecutive.component.css']
})
export class ERCExecutiveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

  readmorebutton(){

    window.open("/team", "_Self");

  }







ngAfterViewInit(){

  }







}
