import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  public arrProjects : any = [];
  public chosenProject : any ;
  public backgroundUrl : any ;
  public contactObj : any = {
    'name' : '',
    'company' : '',
    'email' : '',
    'phone' : '',
    'subject' : '',
    'message' : '',

  }

  public about: any;

  public history:any;

  public values:any;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getabout();
    this.gethistory();
    this.getvalues();
  }
  toggleprojects(projectIndex : any){
    this.chosenProject = this.arrProjects[projectIndex];
    this.backgroundUrl = this.chosenProject.cover_photo;
  }


  getabout(){

    this.http.get('about/page').subscribe(response => {

    if(response['status'].code == "200"){

      this.about = response["result"].about;

    }

    else { this.toastr.error(response['status'].message); }

  });

  }


  gethistory(){

    this.http.get('about/history').subscribe(response => {

    if(response['status'].code == "200"){

      this.history = response["result"];

    }

    else { this.toastr.error(response['status'].message); }

  });

  }


  getvalues(){

    this.http.get('about/values').subscribe(response => {

    if(response['status'].code == "200"){

      this.values = response["result"];

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

}
