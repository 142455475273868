import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-applyjob',
  templateUrl: './applyjob.component.html',
  styleUrls: ['./applyjob.component.css']
})
export class ApplyjobComponent implements OnInit {

  public jobid: any;

  public getJobObj: any;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.route.params.subscribe(queryParams => {

      this.jobid = this.route.snapshot.paramMap.get('id');
      this.getjobbyid();
    });
  }


  getjobbyid(){

    this.http.get('careers/job?id='+this.jobid).subscribe(response => {

    if(response['status'].code == "200"){

      this.getJobObj = response["result"];

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

  applyforjob(){

    this.http.post('careers/apply', {}).subscribe(response => {

    if(response['status'].code == "200"){

      this.toastr.success(response['status'].message);


    }

    else { this.toastr.error(response['status'].message); }

  });

  }

}
