import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ourproject',
  templateUrl: './ourproject.component.html',
  styleUrls: ['./ourproject.component.css']
})
export class OurprojectComponent implements OnInit {


  public overview:any;

  public units:any;

  public images:any;

  public hideValue: true;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getoverview();
    this.getunits();
  }
  ngAfterViewInit(){
      setTimeout(() => {
        // document.getElementById('showslider').style.visibility = "visible";
    },9000);
  }


  getoverview(){

    this.http.get('project/overview').subscribe(response => {

    if(response['status'].code == "200"){

      this.overview = response["result"];
      this.images = response["result"].images;
      console.log(this.overview);
      console.log(this.images);

    }

    else { this.toastr.error(response['status'].message); }

  });

  }


  getunits(){

    this.http.get('project/units').subscribe(response => {

    if(response['status'].code == "200"){

      this.units = response["result"].points;

    }

    else { this.toastr.error(response['status'].message); }

  });

  }


}
