import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onZoom(){
        document.getElementById('myModalzoom').style.display = "block";
        // document.getElementById('img01').setAttribute('src',src);
      }
      closeModal(){
        document.getElementById('myModalzoom').style.display = "none";
      }
}
