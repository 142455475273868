import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-projectoverview',
  templateUrl: './projectoverview.component.html',
  styleUrls: ['./projectoverview.component.css']
})
export class ProjectoverviewComponent implements OnInit {

  public projectoverview: any;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getprojectoverview();
  }

  readmorebutton(){

    window.open("/ourproject", "_Self");

  }

  //project overview

    getprojectoverview(){

      this.http.get('home/project').subscribe(response => {

      if(response['status'].code == "200"){

        this.projectoverview = response["result"];

      }

      else { this.toastr.error(response['status'].message); }

    });

    }

}
