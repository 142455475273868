import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule,Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpInterceptorService } from './http-interceptor.service';
import {SimpleCrypt} from "ngx-simple-crypt";
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule } from 'ngx-bootstrap/carousel';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomepageComponent } from './homepage/homepage.component';
import { FooterComponent } from './footer/footer.component';
import { ProjectoverviewComponent } from './projectoverview/projectoverview.component';
import { ERCExecutiveComponent } from './ercexecutive/ercexecutive.component';
import { EnvironmentalComponent } from './environmental/environmental.component';
import { BecomefamilyComponent } from './becomefamily/becomefamily.component';
import { LatestnewsComponent } from './latestnews/latestnews.component';
import { HeadofficeComponent } from './headoffice/headoffice.component';
import { ManagingmessageComponent } from './managingmessage/managingmessage.component';
import { HomesliderComponent } from './homeslider/homeslider.component';
import { WhoweareComponent } from './whoweare/whoweare.component';
import { RefiningprocessComponent } from './refiningprocess/refiningprocess.component';
import { NumbersComponent } from './numbers/numbers.component';
import { OurbusinessComponent } from './ourbusiness/ourbusiness.component';
import { ContractorsComponent } from './contractors/contractors.component';
import { EnviromentalpageComponent } from './enviromentalpage/enviromentalpage.component';
import { EconomicpageComponent } from './economicpage/economicpage.component';
import { ProductsComponent } from './products/products.component';
import { CommunityComponent } from './community/community.component';
import { CareersComponent } from './careers/careers.component';
import { SubnewsComponent } from './subnews/subnews.component';
import { NewsComponent } from './news/news.component';
import { TeamsComponent } from './teams/teams.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ApplyjobComponent } from './applyjob/applyjob.component';
import { OurprojectComponent } from './ourproject/ourproject.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { CsrComponent } from './csr/csr.component';
import { LifeatercComponent } from './lifeaterc/lifeaterc.component';
import { FaqsComponent } from './faqs/faqs.component';
import { TabsComponent } from './tabs/tabs.component';
import { GalleryComponent } from './gallery/gallery.component';
import { VendorComponent } from './vendor/vendor.component';
import { PopupvendorComponent } from './popupvendor/popupvendor.component';
import { MessagebarComponent } from './messagebar/messagebar.component';
import { SupplymanagementComponent } from './supplymanagement/supplymanagement.component';
import { EducationComponent } from './education/education.component';
import { IncomeComponent } from './income/income.component';
import { YouthComponent } from './youth/youth.component';
import { SupportingComponent } from './supporting/supporting.component';
import { Products2Component } from './products2/products2.component';




const routes: Routes = [

  {path:'',component:HomepageComponent},
  {path:'header',component:HeaderComponent},
  {path:'footer',component:FooterComponent},
  {path:'projectoverview',component:ProjectoverviewComponent},
  {path:'ercexecutive',component:ERCExecutiveComponent},
  // {path:'environmental',component:EnvironmentalComponent},
  {path:'becomefamily',component:BecomefamilyComponent},
  {path:'latestnews',component:LatestnewsComponent},
  {path:'headoffice',component:HeadofficeComponent},
  {path:'managingmessage',component:ManagingmessageComponent},
  {path:'homeslider',component:HomesliderComponent},
  {path:'whoweare',component:WhoweareComponent},
  {path:'refiningprocess',component:RefiningprocessComponent},
  {path:'numbers',component:NumbersComponent},
  {path:'contractors-licensors',component:ContractorsComponent},
  {path:'environmental',component:EnviromentalpageComponent},
  {path:'economic-benfits',component:EconomicpageComponent},
  {path:'products',component:ProductsComponent},
  {path:'corporate-social-responsibility',component:CommunityComponent},
  {path:'careers',component:CareersComponent},
  {path:'subnews/:id',component:SubnewsComponent},
  {path:'tenders',component:NewsComponent},
  {path:'team',component:TeamsComponent},
  {path:'contactus',component:ContactusComponent},
  {path:'applyjob/:id',component:ApplyjobComponent},
  {path:'ourproject',component:OurprojectComponent},
  {path:'about-us',component:AboutusComponent},
  {path:'csr',component:CsrComponent},
  {path:'lifeaterc',component:LifeatercComponent},
  {path:'faqs',component:  FaqsComponent},
  {path:'tabs',component:  TabsComponent},
  {path:'gallery',component:  GalleryComponent},
  {path:'vendor',component:  VendorComponent},
  {path:'supplymanagement',component:  SupplymanagementComponent},
  {path:'education',component:EducationComponent},
  {path:'income',component:IncomeComponent},
  {path:'youth',component:YouthComponent},
  {path:'supporting',component:SupportingComponent},
  {path:'products-2',component:Products2Component},


];




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomepageComponent,
    FooterComponent,
    ProjectoverviewComponent,
    ERCExecutiveComponent,
    EnvironmentalComponent,
    BecomefamilyComponent,
    LatestnewsComponent,
    HeadofficeComponent,
    ManagingmessageComponent,
    HomesliderComponent,
    WhoweareComponent,
    RefiningprocessComponent,
    NumbersComponent,
    OurbusinessComponent,
    ContractorsComponent,
    EnviromentalpageComponent,
    EconomicpageComponent,
    ProductsComponent,
    CommunityComponent,
    CareersComponent,
    SubnewsComponent,
    NewsComponent,
    TeamsComponent,
    ContactusComponent,
    ApplyjobComponent,
    OurprojectComponent,
    AboutusComponent,
    CsrComponent,
    LifeatercComponent,
    FaqsComponent,
    TabsComponent,
    GalleryComponent,
    VendorComponent,
    PopupvendorComponent,
    MessagebarComponent,
    SupplymanagementComponent,
    EducationComponent,
    IncomeComponent,
    YouthComponent,
    SupportingComponent,
    Products2Component,

  ],
  imports: [
    BrowserModule,
    TooltipModule.forRoot(),
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
      RouterModule.forRoot(routes),
      FormsModule,
      HttpClientModule,
      ToastrModule.forRoot(),
      TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
    }),
    MDBBootstrapModule.forRoot()
  ],
  providers: [{ provide: HTTP_INTERCEPTORS , useClass: HttpInterceptorService,multi: true }, ToastrService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http,'./assets/i18n/', '.json');
}
