import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

public projectoverview: any;
public numbersaterc: any;
public whoweare: any;
public impact: any;
public message: any;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
  }


//project overview

  getprojectoverview(){

    this.http.get('home/project').subscribe(response => {

    if(response['status'].code == "200"){

      this.projectoverview = response["result"];

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

//numbers at erc

getnumbersaterc(){

  this.http.get('home/featured').subscribe(response => {

  if(response['status'].code == "200"){

    this.numbersaterc = response["result"];

  }

  else { this.toastr.error(response['status'].message); }

});

}

//numbers at erc


//who we are

getwhoweare(){

  this.http.get('home/about').subscribe(response => {

  if(response['status'].code == "200"){

    this.whoweare = response["result"];

  }

  else { this.toastr.error(response['status'].message); }

});

}

//who we are

//ERC impact

getercimpact(){

  this.http.get('home/impact').subscribe(response => {

  if(response['status'].code == "200"){

    this.impact = response["result"];

  }

  else { this.toastr.error(response['status'].message); }

});

}

//ERC impact


//Director message

getdirectormessage(){

  this.http.get('home/welcome').subscribe(response => {

  if(response['status'].code == "200"){

    this.message = response["result"];

  }

  else { this.toastr.error(response['status'].message); }

});

}

//Director message


}
