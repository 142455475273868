import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityComponent implements OnInit {

  public csroverview: any;
  public csrprograms: any;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getcsroverview();
    this.getcsrprograms();
  }

  getcsroverview(){

    this.http.get('impact/csr/overview').subscribe(response => {

    if(response['status'].code == "200"){

      this.csroverview = response["result"];

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

  getcsrprograms(){

    this.http.get('impact/csr/programs').subscribe(response => {

    if(response['status'].code == "200"){

      this.csrprograms = response["result"];
      this.csrprograms.reverse();
      console.log(this.csrprograms);

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

}
