import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-environmental',
  templateUrl: './environmental.component.html',
  styleUrls: ['./environmental.component.css']
})
export class EnvironmentalComponent implements OnInit {

  public div1:boolean=true;
  public div2:boolean=false;
  public div3:boolean=false;
  public impact: any = [];

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getercimpact();
  }

readmoreenv(){

  window.open("/environmental", "_Self");

}

readmoreeco(){

  window.open("/economic-benfits", "_Self");

}

readmorecsr(){

  window.open("/corporate-social-responsibility", "_Self");

}

div1Function(){
    this.div1=true;
    this.div2=false;
    this.div3=false
}

div2Function(){
    this.div2=true;
    this.div1=false;
    this.div3=false
}

div3Function(){
    this.div3=true;
    this.div2=false;
    this.div1=false
}

//ERC impact

getercimpact(){

  this.http.get('home/impact').subscribe(response => {

  if(response['status'].code == "200"){

    this.impact = response["result"];

  }

  else { this.toastr.error(response['status'].message); }

});

}

//ERC impact

}
