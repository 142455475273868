import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-csr',
  templateUrl: './csr.component.html',
  styleUrls: ['./csr.component.css']
})
export class CsrComponent implements OnInit {

  public csroverview: any;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getcsr();
  }


  getcsr(){

    this.http.get('impact/csr/programs').subscribe(response => {

    if(response['status'].code == "200"){

      this.csroverview = response["result"].contractors;

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

}
