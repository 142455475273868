import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-managingmessage',
  templateUrl: './managingmessage.component.html',
  styleUrls: ['./managingmessage.component.css']
})
export class ManagingmessageComponent implements OnInit {

  public message: any;

  constructor(private route: ActivatedRoute,private http:HttpClient, private router : Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.getdirectormessage();
  }

  //Director message

  getdirectormessage(){

    this.http.get('home/welcome').subscribe(response => {

    if(response['status'].code == "200"){

      this.message = response["result"];
      console.log(this.message);

    }

    else { this.toastr.error(response['status'].message); }

  });

  }

  //Director message

}
